import { Box } from "@chakra-ui/react";
import Blocks from "@components/Blocks";
import { PageBuilderProvider } from "@modules/PageBuilder/PageBuilder.context";
import Head from "next/head";
import { ReactElement } from "react";

import { PageBuilderType } from "./PageBuilder.model";
import PageResolver from "./PageResolver";

function PageBuilderComponent({
  pageData,
  marketData,
  staticData,
}: PageBuilderType): ReactElement {
  if (pageData) {
    return (
      <>
        <Head>
          <title>{pageData.MetaDataTitle}</title>
          <meta name="description" content={pageData.MetaDataDescription} />
          {pageData.HrefLangUrls.map((x) => (
            <link
              rel="alternate"
              key={x.LanguageCode}
              hrefLang={x.LanguageCode}
              href={x.Url}
            />
          ))}
          <link rel="canonical" href={pageData.CanonicalUrl} />
          {pageData.SiteNameMarkup
          ? <script type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: pageData.SiteNameMarkup }}
            >
            </script>
          : null
          }
          {pageData.SiteNavigationMarkup
          ? <script type="application/ld+json"
              dangerouslySetInnerHTML={{ __html: pageData.SiteNavigationMarkup }}
            >
            </script>
          : null
          }
        </Head>
        <PageBuilderProvider data={{ staticData, marketData }}>
          <Box>
            <PageResolver pageData={pageData} />
            <Blocks {...pageData} />
          </Box>
        </PageBuilderProvider>
      </>
    );
  }

  return null;
}

export default PageBuilderComponent;
