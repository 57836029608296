import { Box, Button, Container, useBreakpointValue } from "@chakra-ui/react";
import NextLink from "next/link";
import { ReactElement } from "react";

import VideoComponent from "../../Video";
import type { BodyVideoBlockType } from "./BodyVideoBlock.model";

function BodyVideoBlockComponent(data: BodyVideoBlockType): ReactElement {
  const isMobile = useBreakpointValue({ base: true, lg: false });

  return (
    <Container
      maxW={data.Contain ? "container.xl" : "unset"}
      zIndex="3"
      px={!data.Contain ? 0 : "4"}
      className={data.Kind}
      position="relative"
      sx={data.IsHero && { paddingTop: 0 }}
    >
      {data.OverlayLink?.Url ? (
        <NextLink href={data.OverlayLink?.Url} passHref>
          <Box
            as="a"
            minH="100%"
            minW="100%"
            zIndex="3"
            position="relative"
            cursor="pointer"
          >
            <VideoComponent
              style={{ position: "absolute", pointerEvents: "none" }}
              url={data.VideoUrl}
              mobileUrl={data.MobileVideoUrl}
              muted
              loop
              playing
              playsinline
            />
            {!isMobile && data.OverlayTitle && (
              <Button
                variant={data.IsTextDark ? "heroLight" : "heroDark"}
                position="absolute"
                top="5%"
                left="5%"
              >
                {data.OverlayTitle}
              </Button>
            )}
          </Box>
        </NextLink>
      ) : (
        <VideoComponent
          style={{ position: "absolute", pointerEvents: "none" }}
          url={data.VideoUrl}
          mobileUrl={data.MobileVideoUrl}
          muted
          loop
          playing
          playsinline
        />
      )}
      {isMobile && data.OverlayTitle && (
        <NextLink href={data.OverlayLink?.Url} passHref>
          <Button
            mt={4}
            mr={2}
            variant={data.IsTextDark ? "heroDark" : "heroDark"}
            position="relative"
            left="50%"
            transform="translate(-50%)"
          >
            {data.OverlayTitle}
          </Button>
        </NextLink>
      )}
    </Container>
  );
}

export default BodyVideoBlockComponent;
