import { Box, SimpleGrid } from "@chakra-ui/react";
import type { ProductTeaserType } from "@components/ProductList/ProductList.model";
import { useEffect, useState } from "react";

import ProductImage from "./ProductImage.component";
import ProductTeaserInfo from "./ProductTeaserInfo.component";

function ProductTeaserComponent(
  props: ProductTeaserType & {
    sizes?: string;
    hideBuyButton?: boolean;
    height?: string;
    Designer?: string;
    showVariants?: boolean;
  }
) {
  // Check if device is touch device. If so, do not preload hover image
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  useEffect(() => {
    if ("ontouchstart" in window || navigator.maxTouchPoints > 0) {
      setIsTouchDevice(true);
    }
  }, []);

  if (!props.Image && !props.Images?.length) return;
  return (
    <Box
      className="ProductTeaser"
      as="article"
      my={props.sideBySide ? 4 : "unset"}
    >
      <SimpleGrid
        columns={props.sideBySide ? 2 : 1}
        gap={props.sideBySide ? 2 : 0}
      >
        <Box bg="brand.gray.300" width="100%">
          <ProductImage
            image={props.Image || props.Images[0]}
            splash={props.Splash}
            url={props.PageUrl}
            format={props.ImageSize}
            sizes={props.sizes}
            height={props.height}
            galleryImage={
              !isTouchDevice && props.GalleryImage ? props.GalleryImage : null
            }
          />
        </Box>
        <Box my="auto" order={props.flip ? -1 : "unset"}>
          <ProductTeaserInfo {...props} />
        </Box>
      </SimpleGrid>
    </Box>
  );
}

export default ProductTeaserComponent;
